import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import '../assets/css/LogIn.css';

const LoginForm = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError(false); // Reset login error state
    setErrorMessage(''); // Reset error message state

    const url = `https://cashflowdatabase.concentics.in/api/admincashflow/login/${username}/${password}`;

    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();

      if (result.message === true) {
        // Set the companyId in local storage
        localStorage.setItem('CompanyId', result.user);
        localStorage.setItem('UserName', username);

        onLogin();
        setIsAuthenticated(true);
        console.log(result.user);
      } else {
        setLoginError(true);
        setErrorMessage('Incorrect username or password.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setLoginError(true);
      setErrorMessage('An error occurred while trying to log in. Please try again later.');
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <section>
      <div className="signin">
        <div className="content">
          <h2>Sign In</h2>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="inputBox">
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <i>Username</i>
              </div>
              <div className="inputBox">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i>Password</i>
              </div>
              {loginError && (
                <p className="error-message" style={{color:'red'}}>{errorMessage}</p>
              )}
              <div className="links">
                <a href="#">Forgot Password</a>
                <a href="#">Signup</a>
              </div>
              <div className="inputBox">
                <input type="submit" value="Login" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
