import React from 'react';
import '../../assets/css/Charts.css';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

// Custom bar shape with border radius
const CustomBar = ({ x, y, width, height }) => {
    const radius = 15;

    return (
        <rect
            x={x}
            y={y}
            width={width}
            height={height}
            rx={radius}
            ry={radius}
            fill="#D5D8DA"
            className="custom-bar"
        />
    );
};

// Custom tooltip content
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].payload.name} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const CustomBarChart = ({ data, title }) => {
    return (
        <>
            {title && <span className="chart-title">{title}</span>}
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid stroke="none" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Legend />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                        dataKey="value"
                        barSize={23}
                        shape={<CustomBar data={data} />}
                    />
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default CustomBarChart;
