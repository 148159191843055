import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection';
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../assets/css/header";
import Api from '../api/Api'; 
import CustomTable from './Table/Table'; // Import your CustomTable component
import download from '../assets/img/download.png';
import darkMode from '../assets/img/darkMode.png';

const Ai = {
  monthlyHeadingText: 'Automation Entry',
  formSections: [
    {
      heading: '',
      selectOptions1: [
        { value: 'type1', label: 'Type 1' },
        { value: 'type2', label: 'Type 2' },
      ],
      selectOptions2: [
        { value: 'product1', label: 'Product 1' },
        { value: 'product2', label: 'Product 2' },
      ],
      inputPlaceholder: 'Amount',
      buttonText: 'Start Automation'
    },
  ],
};

const salesData = {
  monthlyHeadingText: 'Monthly Equity Entry',
  formSections: [
    {
      heading: 'Equipment',
      selectOptions1: [
        { value: 'type1', label: 'Type 1' },
        { value: 'type2', label: 'Type 2' },
      ],
      selectOptions2: [
        { value: 'product1', label: 'Product 1' },
        { value: 'product2', label: 'Product 2' },
      ],
      inputPlaceholder: 'Amount/Days',
      buttonText: 'Update'
    },
    {
      heading: 'Long Term Investments',
      selectOptions1: [
        { value: 'type1', label: 'Type 1' },
        { value: 'type2', label: 'Type 2' },
      ],
      selectOptions2: [
        { value: 'product1', label: 'Product 1' },
        { value: 'product2', label: 'Product 2' },
      ],
      inputPlaceholder: 'Amount/Days',
      buttonText: 'Update'
    },
  ],
};

const useStyles = makeStyles(styles);

function Equity() {
  const classes = useStyles();
  const Userid = localStorage.getItem('UserName');
  const CompanyId = localStorage.getItem('CompanyId');
  const [equityData, setEquityData] = useState(null); // State to store API response
  const { response, loading, error } = Api({
    modal: 'calculate-equity',
    UserId: Userid,
    CompanyId: CompanyId,
    method: 'GET' // Specify the method here (e.g., 'GET', 'POST', etc.)
  });

  useEffect(() => {
    if (loading) {
      console.log('Loading...');
    }
    if (error) {
      console.error('Error fetching data:', error.message);
    }
    if (response) {
      console.log('Data fetched successfully:', response);
      setEquityData(response); // Store API response in state
    }
  }, [response, loading, error]);

  // Define table header and rows based on your equityData structure
  const head = [ 'Equity', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const rows = [
    [ 'Beginning Balance', ...Object.values(equityData?.beg_bal || {})],
    [ 'Dividends Paid', ...Object.values(equityData?.div_paid || {})],
    [ 'Paid-in Capital', ...Object.values(equityData?.paid_cap || {})],
    [ 'Ending Balance', ...Object.values(equityData?.end_bal || {})],
  ];

  // Log rows to ensure it's correctly formatted
  console.log('Rows:', rows);

  return (
    <>
      <div className='container '>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='formBoxMonthly'>
              <div className='MonthlyHeading p-2'>
                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
              </div>
              <FormSection sections={Ai.formSections} productAndServicesLabel="Product and Services" selectPlaceholder="Select Value" />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='formBoxMonthly'>
              <div className='MonthlyHeading p-2'>
                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
              </div>
              <FormSection sections={salesData.formSections} productAndServicesLabel="Select type Details" selectPlaceholder="Select Value" />
            </div>
          </div>
        </div>
      </div>
      <div className='container mt-4'>
        <div className='row'>
          <div className='col-lg-12 '>
            {/* Conditionally render table when equityData is available */}
            {equityData && Array.isArray(rows) && rows.length > 0 && (
              <CustomTable head={head} rows={rows} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Equity;
