import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../assets/css/header";
import Api from '../api/Api';

const Ai = {
    monthlyHeadingText: 'Automation Entry',
    formSections: [
        {
            heading: '',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount',
            buttonText: 'Start Automation'
        },
    ],
};

const salesData = {
    monthlyHeadingText: 'Monthly Operating Expenses Entry',
    formSections: [
        {
            heading: 'Salaries & Wages',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update'
        },
        {
            heading: 'Other Operating Expenses',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update'
        },
    ],
};

const head = ['S No', 'Salary & Wages', 'Created At', 'Updated At', ''];
const useStyles = makeStyles(styles);

function AddSalariesWages() {
    const [AddSalariesWages, setAddSalariesWages] = useState([]);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');

    const { response, loading, error } = Api({
        modal: 'add_salary_wages_details',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET'
    });

    useEffect(() => {
        if (loading) {
            console.log('Loading...');
        }
        if (error) {
            console.error('Error fetching data:', error.message);
        }
        if (response) {
            console.log('Data fetched successfully:', response);
            setAddSalariesWages(response.salary_wages_details); // Store API response in state
        }
    }, [response, loading, error]);

    console.log("AddSalariesWages"+AddSalariesWages);

    const rows = AddSalariesWages.map((expense, index) => ({
        id: index + 1,
        accr_exp_name: expense.salary_name,
        created_at: expense.created_at,
        updated_at: expense.updated_at,
        button: <input type='button' className='inputboxButton' onClick={() => handleButtonClick(expense)} value='Change' />
    }));

    const arrayOfArrays = rows.map(row => [
        row.id,
        row.accr_exp_name,
        row.created_at,
        row.updated_at,
        row.button
    ]);

    const handleButtonClick = (expense) => {
        // Handle button click logic here, e.g., open a modal, update state, etc.
        console.log('Button clicked for:', expense);
    };

    return (
        <>
            <div className='container '>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={Ai.formSections} productAndServicesLabel="Product and Services" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={salesData.formSections} productAndServicesLabel="Select type Details" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        <CustomTable head={head} rows={arrayOfArrays} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddSalariesWages;
