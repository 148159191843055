import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomTable from './Table/Table';
import "../assets/css/Table.css";
import "../assets/css/headerCustom.css"
import darkMode from '../assets/img/darkMode.png';

const incomeStatementRows = [
    ['Revenue', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,4000],
    ['Cost of Sold Goods', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,4500],
    ['Gross Profit', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,8900],
    ['Operating Expenses', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,15000],
    ['Salaries & Wages', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7800],
    ['Other Operating Expenses', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Depreciation', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,5600],
    ['Total Operating Expenses', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Interest Expenses', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7805],
    ['Earning Before Tax', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9856],
    ['Income Taxes', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7980],
    ['Net Income', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
];

const balanceSheetRows = [
    ['Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,4000],
    ['Currant Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,4500],
    ['Cash and Cash Equivalents', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,8900],
    ['Account Receivalble', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,15000],
    ['Salaries & Wages', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7800],
    ['Inventories', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Other Current Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,5600],
    ['Total Current Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Fixed Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7805],
    ['Property, Plant & Equipment', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9856],
    ['Long-Term Investments', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7980],
    ['Total Fixed Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,5860],
    ['Total Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9300],
    ['Accrued Expenses', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Short-Term Debt', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,8700],
    ['Total Current Liabilities', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9450],
    ['Long-Term Debt', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7895],
    ['Total Long-Term Liabilities', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9586],
    ['Owner’s Equity', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7890],
    ['Owner’s Investment', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,6500],
    ['Retained Earnings', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7800],
    ['Net Income', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
];

const cashFlowRows = [
    ['Beginning Month Balance', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,4000],
    ['Operations', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,4500],
    [' Cash Receipt from Customers', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,8900],
    ['Cash Paid to Suppliers', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,15000],
    ['Salaries & Wages', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7800],
    ['Total Current Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Fixed Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,5600],
    ['Property, Plant & Equipment', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Long-Term Investments', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7805],
    ['Total Fixed Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9856],
    ['Total Assets', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7980],
    ['Liabilities', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,5860],
    ['Account Payable', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9300],
    ['Accrued Expenses', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
    ['Short-Term Debt', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,8700],
    ['Total Current Liabilities', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9450],
    ['Long-Term Debt', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7895],
    ['Total Long-Term Liabilities', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9586],
    ['Owner’s Equity', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7890],
    ['Owner’s Investment', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,6500],
    ['Retained Earnings', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,7800],
    ['Net Income', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300,9800],
];

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Sum"];
const incomeStatementHead = ["IncomeStatement", ...months];
const balanceSheetHead = ["Balance Sheet", ...months];
const cashFlowHead = ["Cash Flow", ...months];

export default function Report() {
    const [activeTable, setActiveTable] = useState('Income Statement');
    const [darkModeEnabled, setDarkModeEnabled] = useState(false);

    const handleTableChange = (tableName) => {
        setActiveTable(tableName);
    };

    const renderTable = () => {
        switch (activeTable) {
            case 'Income Statement':
                return <CustomTable head={incomeStatementHead} rows={incomeStatementRows} />;
            case 'Balance Sheet':
                return <CustomTable head={balanceSheetHead} rows={balanceSheetRows} />;
            case 'Cash Flow':
                return <CustomTable head={cashFlowHead} rows={cashFlowRows} />;
            default:
                return null;
        }
    };
    const handleDarkModeToggle = () => {
        setDarkModeEnabled(!darkModeEnabled);
        if (!darkModeEnabled) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    };

    return (
        <>
            <div className=' mb-3 text-center '>
                <div className='containerReportBox p-2 position-fixed bgcolor buttonBox'>
                    <div className="row p-2  ">
                        <div className='col-lg-12 d-flex buttonReportDiv'>
                            <div className={`buttonReports text-center ${activeTable === 'Income Statement' ? 'isActive' : ''}`} onClick={() => handleTableChange('Income Statement')}>Income Statement</div>
                            <div className={`buttonReports text-center ${activeTable === 'Balance Sheet' ? 'isActive' : ''}`} onClick={() => handleTableChange('Balance Sheet')}>Balance sheet</div>
                            <div className={`buttonReports text-center ${activeTable === 'Cash Flow' ? 'isActive' : ''}`} onClick={() => handleTableChange('Cash Flow')}>Cash Flow</div>
                        </div>
                        {/* <div className='col-lg-5'>
                            <div className='float-end'>
                                <img src={darkMode} alt="darkModeIcon" className='reportIcon' onClick={handleDarkModeToggle} />
                            </div>
                        </div> */}
                    </div>
                </div>  
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 mt-2'>
                        {renderTable()}
                    </div>
                </div>
            </div>
        </>
    );
}





