import React, { useState, useEffect } from 'react';

const Api = ({ modal, UserId, CompanyId, method, postData }) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false); // Initial loading state set to false
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const year = 2023; // Assuming year is constant or passed via props
        const url = `https://cashflowdatabase.concentics.in/api/${modal}?year=${year}&comp_id=${CompanyId}`;
        const options = {
          method: method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: method === 'POST' ? JSON.stringify(postData) : undefined // Use undefined for GET requests
        };

        const response = await fetch(url, options);

        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }

        const result = await response.json();
        setResponse(result); // Store the entire response object
        setError(null); // Clear any previous errors on successful response
      } catch (error) {
        setError(error.message); // Set specific error message or handle differently as per your needs
      } finally {
        setLoading(false); // Always set loading to false whether success or failure
      }
    };

    fetchData();
  }, [modal, UserId, CompanyId, method, postData]);

  return { response, loading, error };
};

export default Api;
