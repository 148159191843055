import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NavLink } from 'react-router-dom'; 
import navConfig from "../../layout/Navigation";

const Sidebar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNavConfig, setFilteredNavConfig] = useState(navConfig);

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    const filteredItems = navConfig.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredNavConfig(filteredItems);
  };

  return (
    <div className="border-end bg-white" id="sidebar-wrapper">
      <div className="search-box">
        <input
          type="text"
          className="SerchBoxSidebar"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
      <div className="list-group list-group-flush">
        {filteredNavConfig.map((item, index) => (
          <div className="borderRedius" key={index}>
            <NavLink className="list-group-item" to={item.path}>
              {item.title}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
