import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../assets/css/header";
import Api from '../api/Api';

const Ai = {
    monthlyHeadingText: 'Product Detail',
    formSections: [
        {
            heading: '',
            inputPlaceholder: 'Product Name',
            buttonText: 'Update'
        },
    ],
};



const head = ['S No', 'Products', 'Created At', 'Updated At', ''];
const useStyles = makeStyles(styles);

function AddProduct() {
    const [AddProducts, setAddProducts] = useState([]);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');

    const { response, loading, error } = Api({
        modal: 'add_product',
        CompanyId: CompanyId,
        method: 'GET'
    });

    useEffect(() => {
        if (loading) {
            console.log('Loading...');
        }
        if (error) {
            console.error('Error fetching data:', error.message);
        }
        if (response) {
            console.log('Data fetched successfully:', response);
            setAddProducts(response.products); // Store API response in state
        }
    }, [response, loading, error]);


    const rows = AddProducts.map((expense, index) => ({
        id: index + 1,
        accr_exp_name: expense.product_name,
        created_at: expense.created_at,
        updated_at: expense.updated_at,
        button: <input type='button' className='inputboxButton' onClick={() => handleButtonClick(expense)} value='Change' />
    }));

    const arrayOfArrays = rows.map(row => [
        row.id,
        row.accr_exp_name,
        row.created_at,
        row.updated_at,
        row.button
    ]);

    const handleButtonClick = (expense) => {
        // Handle button click logic here, e.g., open a modal, update state, etc.
        console.log('Button clicked for:', expense);
    };

    return (
        <>
            <div className='container '>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={Ai.formSections} productAndServicesLabel="Product and Services" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        <CustomTable head={head} rows={arrayOfArrays} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddProduct;
