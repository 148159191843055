import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table';
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../assets/css/header";
import Api from '../api/Api';

const Ai = {
    monthlyHeadingText: 'Automation Entry',
    formSections: [
        {
            heading: '',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount',
            buttonText: 'Start Automation'
        },
    ],
};

const salesData = {
    monthlyHeadingText: 'Monthly Operating Expenses Entry',
    formSections: [
        {
            heading: 'Salaries & Wages',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update'
        },
        {
            heading: 'Other Operating Expenses',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update'
        },
    ],
};

const head = ["Operating Expenses", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const useStyles = makeStyles(styles);

function SalesInventories() {
    const classes = useStyles();
    const [operatingExpenses, setOperatingExpenses] = useState(null);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');

    // Fetch data from API
    const { response, loading, error } = Api({
        modal: 'calculate-sales-inv',
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET' // Specify the method here (e.g., 'GET', 'POST', etc.)
    });

    useEffect(() => {
        if (loading) {
            console.log('Loading...');
        }
        if (error) {
            console.error('Error fetching data:', error.message);
        }
        if (response) {
            console.log('Data fetched successfully:', response);
            setOperatingExpenses(response); // Store API response in state
        }
    }, [response, loading, error]);

    // Function to generate rows dynamically based on operatingExpenses data
    const generateRows = () => {
        if (!operatingExpenses) return [];

        const sections = {
            'Sales': 'Sales',
            'Gross Margin %': 'Gross Margin %',
            'Cost Of Sold Goods':'Cost Of Sold Goods'
        };

        const rows = [];

        Object.keys(sections).forEach((key) => {
            const sectionHeading = sections[key];
            const sectionData = operatingExpenses[key];

            if (sectionData) {
                rows.push([sectionHeading]);

                Object.keys(sectionData).forEach((subKey) => {
                        rows.push([subKey, ...months.map(month => sectionData[subKey][month] || '0')]);
                });

                rows.push([]); // Empty row for separation
            }
        });

        return rows;
    };

    const rows = generateRows();

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={Ai.formSections} productAndServicesLabel="Product and Services" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={salesData.formSections} productAndServicesLabel="Select type Details" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <CustomTable head={head} rows={rows} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesInventories;
