import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import CustomTable from './Table/Table';
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../assets/css/header";
import Api from '../api/Api'; // Adjust the path based on your project structure

const useStyles = makeStyles(styles);

const head = ["Debt", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]; // Assuming these are your month labels

function Debts() {
    const classes = useStyles();
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const [equityData, setEquityData] = useState(null); // State to store API response
    console.log('CompanyId'+CompanyId);
    console.log('Userid'+Userid);
    // Fetch data from API
    const { response, loading, error } = Api({
        modal: 'calculate-debts', // Adjust as necessary based on your API endpoint
        UserId: Userid,
        CompanyId: CompanyId,
        method: 'GET' // Specify the method here (e.g., 'GET', 'POST', etc.)
    });

    useEffect(() => {
        if (loading) {
            console.log('Loading...');
        }
        if (error) {
            console.error('Error fetching data:', error.message);
        }
        if (response) {
            console.log('Data fetched successfully:', response);
            setEquityData(response); // Store API response in state 
        }
    }, [response, loading, error]);

    // Define rows based on equityData structure
    const rows = [
        ['Short-Term Debt',],
        ['Beginning Month Balance', ...months.map(month => equityData?.short_term_beg_bal[month] || '0')],
        ['Loan Raised', ...months.map(month => equityData?.short_loan_raised[month] || '0')],
        ['From Long-Term Debt', ...months.map(month => equityData?.from_long_term_debt[month] || '0')],
        ['Repayment of Debt', ...months.map(month => equityData?.short_repay_debt[month] || '0')],
        ['End of Month Balance', ...months.map(month => equityData?.short_end_bal[month] || '0')],
        ['Long-Term Debt',],
        ['Beginning Month Balance', ...months.map(month => equityData?.long_term_beg_bal[month] || '0')],
        ['Loan Raised', ...months.map(month => equityData?.long_loan_raised[month] || '0')],
        ['To Short-Term Debt', ...months.map(month => equityData?.to_short_term_debt[month] || '0')],
        ['End of Month Balance', ...months.map(month => equityData?.long_end_bal[month] || '0')],
        ['',],
        ['Total End of Month Balance', ...months.map(month => equityData?.final_sum[month] || '0')],
        ['',],
        ['Interest Expenses',],
        ['Interest Rate Monthly', ...months.map(month => equityData?.int_rate[month]+"%" || '0%')],
        ['Interest Expense', ...months.map(month => equityData?.final_sum[month] || '0')],
    ];

    return (
        <>
            <div className='container '>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>Automation Entry</span>
                            </div>
                            {/* <FormSection sections={Ai.formSections} productAndServicesLabel="Product and Services" selectPlaceholder="Select Value" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>Monthly Debt Entry</span>
                            </div>
                            {/* <FormSection sections={salesData.formSections} productAndServicesLabel="Select type Details" selectPlaceholder="Select Value"/> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        {/* Conditionally render table when equityData is available */}
                        {equityData && Array.isArray(rows) && rows.length > 0 && (
                            <CustomTable head={head} rows={rows} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Debts;
