import React, { useState } from 'react';

function AiReports() {
    const [openSection, setOpenSection] = useState(null);

    const toggleSection = (index) => {
        setOpenSection(openSection === index ? null : index);
    };

    return (
       <>
         <div className='container'>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='formBoxMonthly'>
                        <div className='MonthlyHeading p-2'>
                            <span className='MonthlyHeadingText'>Type your query for search</span>
                        </div>
                        <div className='FormContainer'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='formHeadingSales'><label>Company overview</label></div>
                                        <div className='inputboxdiv mt-2 d-flex'>
                                            <textarea className='inputboxTextArea' placeholder="Company overview to create ai report"></textarea>
                                            <div className='inputboxdiv'>
                                                <div className='formHeadingSales'><label>Question</label></div>
                                                <input type='text' className='inputbox' placeholder="Question to get ai report" />
                                                <button className='inputboxButton'>Create AI Report</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {['Summary', 'Income Statement', 'Balance Sheet', 'Cash flow'].map((section, index) => (
            <div className='container mt-4' key={index}>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading aiButtonCard p-2' onClick={() => toggleSection(index)}>
                                <span className='MonthlyHeadingText'>{section}</span>
                            </div>
                            {openSection === index && (
                                <div className='SummaryDetails'>
                                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ))}
       </>
    );
}

export default AiReports;
