import React from 'react';

function FormSection({ sections, productAndServicesLabel, selectPlaceholder }) {
    return (
        <>
            <div className='FormContainer'>
                {sections.map((section, index) => (
                    <div className='container ' key={index}>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='formHeadingSales'><label>{section.heading}</label></div>
                                <div className='inputboxdiv'>
                                    {section.selectOptions1 && (
                                        <select className='inputbox'>
                                            <option value=''>{selectPlaceholder}</option>
                                            {section.selectOptions1.map((option, idx) => (
                                                <option key={idx} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    )}
                                    {section.selectOptions2 && (
                                        <select className='inputbox'>
                                            <option value=''>{productAndServicesLabel}</option>
                                            {section.selectOptions2.map((option, idx) => (
                                                <option key={idx} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    )}
                                    {section.inputPlaceholder && (
                                        <input type='text' className='inputbox' placeholder={section.inputPlaceholder} />
                                    )}
                                    <input type='button' className='inputboxButton' value={section.buttonText} />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default FormSection;
