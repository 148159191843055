import React from 'react';
import BarChart from '../components/Charts/BarCharts';
import LineChart from '../components/Charts/LineChart';


// Data for the chart
const LineChartdata = [
    { name: 'Jan', value: 5 },
    { name: 'Feb', value: 89 },
    { name: 'Mar', value: 95 },
    { name: 'Apr', value: 32 },
    { name: 'May', value: 65 },
    { name: 'Jun', value: 19 },
    { name: 'Jul', value: 50 },
    { name: 'Aug', value: 84 },
    { name: 'Sep', value: 36 },
    { name: 'Oct', value: 56 },
    { name: 'Nov', value: 29 },
    { name: 'Dec', value: 78 },
  ];
  // Data for the chart
const BarChartdata = [
    { name: 'Jan', value: 10, color: '#d3d3d3' },
    { name: 'Feb', value: 20, color: '#d3d3d3' },
    { name: 'Mar', value: 30, color: '#d3d3d3' },
    { name: 'Apr', value: 40, color: '#d3d3d3' },
    { name: 'May', value: 50, color: '#d3d3d3' },
    { name: 'Jun', value: 60, color: '#d3d3d3' },
    { name: 'Jul', value: 70, color: '#d3d3d3' },
    { name: 'Aug', value: 80, color: '#d3d3d3' },
    { name: 'Sep', value: 90, color: '#d3d3d3' },
    { name: 'Oct', value: 100, color: '#d3d3d3' },
    { name: 'Nov', value: 110, color: '#d3d3d3' },
    { name: 'Dec', value: 120, color: '#d3d3d3' },
];

const CustomBarChart = () => {
    return (
        <>
            <div className='container chart-container'>
                <div className='row'>
                    <div className='col-lg-12 d-flex chart-col'>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                               <BarChart data={BarChartdata} title='Cash and Cash Equivalents'/>
                            </div>
                        </div>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                                <LineChart  data={LineChartdata} title='Cash Ratio '/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container chart-container mt-2' style={{marginBottom:100}}>
                <div className='row'>
                    <div className='col-lg-12 d-flex chart-col'>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                                <LineChart data={LineChartdata} title='Net Income '/>
                            </div>
                        </div>
                        <div className='ChartBox col-lg-6 mt-2'>
                            <div className=''>
                                <BarChart data = {BarChartdata} title='Revenue'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default CustomBarChart;
