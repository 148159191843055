import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection';
import CustomTable from './Table/Table';
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../assets/css/header";
import Api from '../api/Api'; // Adjust the path based on your project structure

const Ai = {
    monthlyHeadingText: 'Automation Entry',
    formSections: [
        {
            heading: '',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount',
            buttonText: 'Start Automation'
        },
    ],
};

const salesData = {
    monthlyHeadingText: 'Monthly Operating Expenses Entry',
    formSections: [
        {
            heading: 'Equipment',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update'
        },
        {
            heading: 'Long Term Investments',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update'
        },
    ],
};

const head = ["Fixed Assets", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]; // Assuming these are your month 

const useStyles = makeStyles(styles);

function FixedAssets() {
    const classes = useStyles();
    const [fixedAssetsData, setFixedAssetsData] = useState(null);
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    const { response, loading, error } = Api({
      modal: 'calculate-fixed-assets',
      UserId: Userid,
      CompanyId: CompanyId,
      method: 'GET' // Specify the method here (e.g., 'GET', 'POST', etc.)
    });
  
    useEffect(() => {
      if (loading) {
        console.log('Loading...');
      }
      if (error) {
        console.error('Error fetching data:', error.message);
      }
      if (response) {
        console.log('Data fetched successfully:', response);
        setFixedAssetsData(response); // Store API response in state
      }
    }, [response, loading, error]);
  
   
    const rows = [
        ['Equipment	',''],
        ['Beginning Month Balance', ...months.map(month => fixedAssetsData?.equip_beg_mont_bal[month] || '0')],
        ['Purchase of Property', ...months.map(month => fixedAssetsData?.pur_of_prop[month] || '0')],
        ['Sale of Equimpment', ...months.map(month => fixedAssetsData?.sales_equip[month] || '0')],
        ['Depreciation', ...months.map(month => fixedAssetsData?.equip_deprec[month] || '0')],
        ['End of Month Balance', ...months.map(month => fixedAssetsData?.equip_end_bal[month] || '0')],
        ['Long Term Investments	',''],
        ['Beginning Month Balance', ...months.map(month => fixedAssetsData?.long_trm_beg_mont_bal[month] || '0')],
        ['Purchase of Investments', ...months.map(month => fixedAssetsData?.pur_of_invest[month] || '0')],
        ['Sales of Investments', ...months.map(month => fixedAssetsData?.sales_of_invest[month] || '0')],
        ['Depreciation', ...months.map(month => fixedAssetsData?.long_trm_deprec[month] || '0')],
        ['End of Month Balance', ...months.map(month => fixedAssetsData?.long_end_bal[month] || '0')],
    ];

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={Ai.formSections} productAndServicesLabel="Product and Services" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={salesData.formSections} productAndServicesLabel="Select type Details" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12 '>
                        {/* Conditionally render table when fixedAssetsData is available */}
                        {fixedAssetsData && Array.isArray(rows) && rows.length > 0 && (
                            <CustomTable head={head} rows={rows} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FixedAssets;
