import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BrowserRouter as Router, Routes, Route, NavLink, useLocation } from 'react-router-dom'; 
import navConfig from "../../layout/Navigation";

const messageIcon = require('../../assets/img/message.png');
const logoutIcon = require('../../assets/img/logout.png');
const logo = require('../../assets/img/logoicon.png');

const Navbar = ({ onToggleSidebar, onLogout }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    onToggleSidebar(); // Call the function to toggle the sidebar
  };

  // Get the current page title from navConfig based on the current path
  const currentPage = navConfig.find(item => item.path === location.pathname);
  const pageTitle = currentPage ? currentPage.title : 'Dashboard';

  return (
    <nav className="navbar navbar-expand-lg navbar-light border-bottom">
      <div className="container-fluid">
        <div><img src={logo} alt="Logo" className="logo" /></div>
        <span className="toggleIcon" id="sidebarToggle" onClick={handleToggleSidebar}>
          {isSidebarOpen ? <ArrowBackIcon /> : <MenuIcon />} {/* Toggle between MenuIcon and ArrowBackIcon */}
        </span>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className='ml-4 headingText'>{pageTitle}</div>
        <div className="collapse navbar-collapse align-items-end justify-content-end" id="navbarSupportedContent">
          <div className="search-tab align-items-end justify-content-end">
            <div className="align-items-end justify-content-end">
              <input type="text" className="SerchBoxHeader" placeholder="Search..." />
            </div>
          </div>
          <img src={messageIcon} alt="messageIcon" className="messageIcon" />
          <img src={logoutIcon} alt="logoutIcon" className="messageIcon" onClick={onLogout} style={{ cursor: 'pointer' }} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
