
import React, { useState, useEffect } from 'react';
import FormSection from './Form/FormSection'; // Adjust the path as necessary
import '../assets/css/Form.css';
import CustomTable from './Table/Table'
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "../assets/css/header";
import Api from '../api/Api';

const Ai = {
    monthlyHeadingText: 'Automation Entry',
    formSections: [
        {
            heading: '',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            inputPlaceholder: 'Amount',
            buttonText: 'Start Automation'
        },

    ],
};
const salesData = {
    monthlyHeadingText: 'Monthly Accrued Expenses Entry',
    formSections: [
        {
            heading: 'Equipment',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update'
        },
        {
            heading: 'Long Term Investments',
            selectOptions1: [
                { value: 'type1', label: 'Type 1' },
                { value: 'type2', label: 'Type 2' },
            ],
            selectOptions2: [
                { value: 'product1', label: 'Product 1' },
                { value: 'product2', label: 'Product 2' },
            ],
            inputPlaceholder: 'Amount/Days',
            buttonText: 'Update Accrued Expenses'
        },
        
    ],
};
const download = require('../assets/img/download.png');
const darkMode = require('../assets/img/darkMode.png');


const rows = [
    ['Earning Before Tax ', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300],
    ['Tax Rate %', 0.004+"%", 0.045+"%", 0.089+"%", 1.20+'%',0.068+'%' , 0.256+'%', 4500, 700, 1100, 600, 900, 1300],
    ['Income Tex', 1200, 1300, 1400, 1600, 1200, 1800, 4500, 700, 1100, 600, 900, 1300],
];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const head = ["IncomeTex", ...months,];
const useStyles = makeStyles(styles);

function IncomeTex() {
    const classes = useStyles();
    const Userid = localStorage.getItem('UserName');
    const CompanyId = localStorage.getItem('CompanyId');
    console.log('Userid' + Userid);
    console.log('CompanyId' + CompanyId);
    const [inconTexData, setInconTexData] = useState(null); // State to store API response
    const { response, loading, error } = Api({
      modal: 'incometex',
      UserId: Userid,
      CompanyId: CompanyId,
      method: 'GET' // Specify the method here (e.g., 'GET', 'POST', etc.)
    });
    useEffect(() => {
        if (loading) {
          console.log('Loading...');
        }
        if (error) {
          console.error('Error fetching data:', error.message);
        }
        if (response) {
          console.log('Data fetched successfully:', response);
          setInconTexData(response); // Store API response in state
        }
      }, [response, loading, error]);
    return (
        <>
            <div className='container '>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{Ai.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={Ai.formSections} productAndServicesLabel="Product and Services" selectPlaceholder="Select Value" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='formBoxMonthly'>
                            <div className='MonthlyHeading p-2'>
                                <span className='MonthlyHeadingText'>{salesData.monthlyHeadingText}</span>
                            </div>
                            <FormSection sections={salesData.formSections} productAndServicesLabel="Select type Details" selectPlaceholder="Select Value"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-4'>
                <div className='row'>
                    {/* <div className='float-end'>
            <img src={download} alt="messageIcon" className={classes.reportIcon} />
            <img src={darkMode} alt="logoutIcon" className={classes.reportIcon} />
          </div> */}
                    <div className='col-lg-12 '>
                        <CustomTable head={head} rows={rows} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default IncomeTex;