import React, { useState } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';

const download = require('../../assets/img/download.png');
const darkMode = require('../../assets/img/darkMode.png');

const CustomTable = ({ head, rows }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleDownloadClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleDownloadAsPDF = () => {
    const table = document.getElementById('custom-table');
    const options = {
      logging: true, // Enable logging for debugging
      scale: 2, // Increase scale for better quality
      useCORS: true, // Enable CORS to capture images from external sources
    };
  
    // Delay before capturing the table
    setTimeout(() => {
      html2canvas(table, options).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save('table.pdf');
      });
    }, 1000); // Adjust delay time as needed
    setShowDropdown(false);
  };
  

  const handleDownloadAsExcel = () => {
    const table = document.getElementById('custom-table');
    const wb = XLSX.utils.table_to_book(table, { sheet: 'Sheet JS' });
    XLSX.writeFile(wb, 'table.xlsx');
    setShowDropdown(false);
  };

  return (
    <>
      <div className='float-end'>
        <img
          src={download}
          alt="downloadIcon"
          className='reportIcon'
          onClick={handleDownloadClick}
        />
         {showDropdown && (
          <div className='dropdown-menu'>
            <button className='dropdown-item' onClick={handleDownloadAsPDF}>Download as PDF</button>
            <button className='dropdown-item' onClick={handleDownloadAsExcel}>Download as Excel</button>
          </div>
        )}
      </div>
      <div className='tabel-Cantainer'>
        <table
          id="custom-table"
          className="table table-hover table-bordered table-container"
          style={{ borderCollapse: '', borderSpacing: '0', borderRadius: '15px', overflow: 'hidden', backgroundColor: 'white' }}
        >
          <thead>
            <tr>
              {head.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="text-left"
                  style={{ color: '#114CB2', fontWeight: '500',backgroundColor:'#F6F6F6' }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="text-left">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

CustomTable.propTypes = {
  head: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default CustomTable;
