import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from '../components/Dashboard-Header-Sidebar/Sidebar';
import NavBar from '../components/Dashboard-Header-Sidebar/Navbar';
import navConfig from './Navigation'; // Ensure this path is correct

const Header = ({ onLogout }) => {
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);

  useEffect(() => {
    const isToggled = localStorage.getItem('sb|sidebar-toggle') === 'true';
    setIsSidebarToggled(isToggled);
  }, []);

  const handleToggleSidebar = () => {
    setIsSidebarToggled(!isSidebarToggled);
    localStorage.setItem('sb|sidebar-toggle', !isSidebarToggled);
  };

  return (
    <Router>
      <div>
        <NavBar onToggleSidebar={handleToggleSidebar} onLogout={onLogout} />
        <div className={`d-flex ${isSidebarToggled ? 'sb-sidenav-toggled' : ''}`} id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div id="content">
              <Routes>
                {navConfig.map((item, index) => (
                  <Route key={index} path={item.path} element={<item.component />} />
                ))}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Header;
